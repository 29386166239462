<template>

<Header></Header>

  <div class="desktop-account-page">
    <div class="main-height">
      <section class="account">
        <div class="container">
          <!-- ACCOUNT  -->
          <div class="card bg-light border-0 rounded-4 card-body p-sm-5 pt-6 text-decoration-none">
          <div class="main-heading">
            <h2 class="text-left">Account</h2>
          </div>
          <hr class="solid">
          <div class="row">
            <div class="col-3">
              <div class="sub-heading">
                <h5>MEMBERSHIP & BILLING</h5>
              </div>
            </div>
            <div class="col-6">
              <div class="membership-details">
                <p class="email">{{ this.userProfile.email }}</p>
              </div>
            </div>
            <div class="col-3">
              <!-- change-password button  -->
              <div class="d-flex justify-content-end mb-1">
                <a class="account-button" role="button" @click="doChangePassword">Change Password</a>
              </div>
              <!-- Conatct support button  -->
              <div class="d-flex justify-content-end mb-1">
                <a class="account-button" role="button" @click="doContactSupport">Contact Support</a>
              </div>
            </div>
          </div>

          <!-- VIDEO SETTINGS  -->
          <hr v-if="!this.userPlan.trial" class="solid">
          <div v-if="!this.userPlan.trial" class="row">
            <div class="col-3">
              <div class="sub-heading">
                <h5>VIDEO SETTINGS</h5>
              </div>
            </div>
            <div class="col-6">
              <div class="video-details">
                <p class="fw-bold d-inline">CDN Region: </p>
                <select v-model="selectedRegion" @change="handleRegionChange" class="dropdown-size-large" style="margin-left: 12px;">
                  <option v-for="(label, value) in regions" :key="value" :value="value">{{ label }}</option>
                </select>
                <div class="info-container">
                  <img class="info-img d-inline" src="../assets/info.svg" alt="">
                  <div class="info-text info-text-size-large">Buffering issues? Switch to a closer region. Otherwise, let our CDN auto-select for optimal performance.</div>
                </div>
              </div>
              <div class="video-details">
                <p class="fw-bold d-inline">Video Quality: </p>
                <select v-model="selectedVideoQuality" @change="handleVideoQualityChange" class="dropdown-size-large">
                  <option v-for="quality in orderedVideoQualities" :key="quality.name" :value="quality.name">{{ quality.name }}</option>
                </select>
                <div class="info-container">
                  <img class="info-img d-inline" src="../assets/info.svg" alt="">
                  <div class="info-text info-text-size-large">Choose playback quality! Overrides auto for smoother streaming on varied connections.</div>
                </div>
              </div>
            </div>
          </div>
          <!-- PLAN DETAILS   -->
          <hr class="solid">
          <div v-if="this.userPlan != null">
            <div class="row">
              <div class="col-3">
                <div class="sub-heading">
                  <h5>PLAN DETAILS</h5>
                </div>
              </div>
              <div class="col-6">
                <div class="plan-details">
                  <div>
                    <div class="d-flex">
                      <p class="fw-bold">Plan: &nbsp;</p>
                      <p>Paid - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
                    </div>
                    <div class="d-flex">
                      <p class="fw-bold">Expiry: &nbsp;</p>
                      <p>{{this.planEndDate}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="d-flex justify-content-end mb-1">
                  <!-- Button trigger modal -->
                  <a role="button" class="account-button" data-toggle="modal" data-target="#exampleModalCenter" @click="showPlanModal = true">
                    Renew / Upgrade
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- SETTINGS  -->
          <hr class="solid">
          <div class="row">
            <div class="col-3">
              <div class="sub-heading">
                <h5 class="text-left">SETTINGS</h5>
              </div>
            </div>
            <div class="col-6">
              <!-- button  -->
              <div class="mb-1">
                <a class="account-button" role="button" @click="doSignOut">Sign out</a>
              </div>
              <!-- button  -->
              <div>
                <!-- Button trigger modal -->
                <a role="button" class="account-button" data-toggle="modal" data-target="#exampleModalCenter" @click="showModal = true">Sign out
                  from all devices
                </a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  </div>


  <div class="mobile-account-page">
    <section class="account-page">
      <!-- MY ACCOUNT  -->
      <div class="row mx-1">
        <div class="account-inner-container">
          <div class="col-sm-12 col-12 mt-2 text-justify">
            <!-- ACCOUNT  -->
            <div class="box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">MEMBERSHIP & BILLING</h5>
                <hr class="solid">
                <p class="email">{{ this.userProfile.email }}</p>
                <hr class="solid">
                <!--change password button  -->
                <div>
                  <a class="account-button" role="button" @click="doChangePassword">Change Password</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <hr class="solid">
                <!-- contact support button  -->
                <div>
                  <a role="button" class="account-button" @click="doContactSupport">Contact Support</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </div>
            <!-- VIDEO SETTINGS  -->
            <div v-if="!this.userPlan.trial" class="my-3 box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">VIDEO SETTINGS</h5>
                <hr class="solid">
                <div class="video-details">
                  <p class="fw-bold d-inline">CDN Region: </p>
                  <select v-model="selectedRegion" @change="handleRegionChange" class="dropdown-size-small" style="margin-left: 12px;">
                    <option v-for="(label, value) in regions" :key="value" :value="value">{{ label }}</option>
                  </select>
                  <div class="info-container">
                    <img class="info-img d-inline" src="../assets/info.svg" alt="">
                    <div class="info-text info-text-size-small">Buffering issues? Switch to a closer region. Otherwise, let our CDN auto-select for optimal performance.</div>
                  </div>
                </div>
                <hr class="solid">
                <div class="video-details">
                  <p class="fw-bold d-inline">Video Quality: </p>
                  <select v-model="selectedVideoQuality" @change="handleVideoQualityChange" class="dropdown-size-small">
                    <option v-for="quality in orderedVideoQualities" :key="quality.name" :value="quality.name">{{ quality.name }}</option>
                  </select>
                  <div class="info-container">
                    <img class="info-img d-inline" src="../assets/info.svg" alt="">
                    <div class="info-text info-text-size-small">Choose playback quality! Overrides auto for smoother streaming on varied connections.</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PLAN DETAILS   -->
            <div class="my-3 box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">PLAN DETAILS</h5>
                <hr class="solid mt-0">
                <div class="d-flex">
                  <p class="fw-bold">Paid: &nbsp;</p>
                  <p>Paid - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
                </div>
                <hr class="solid mt-0">
                <div class="d-flex">
                  <p class="fw-bold">Expiry: &nbsp;</p>
                  <p>{{this.planEndDate}}</p>
                </div>
                <hr class="solid mt-0">
                <div>
                <a role="button" @click="showPlanModal = true" class="account-button" data-toggle="modal" data-target="#exampleModalCenter">
                  Renew / Upgrade
                </a>
                <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
              </div>
            </div>
            <!-- SETTINGS  -->
            <div class="my-3 box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">SETTINGS</h5>
                <hr class="solid">
                <!-- button  -->
                <div>
                  <a role="button" class="account-button" @click="doSignOut">Sign out</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <hr class="solid">
                <!-- button  -->
                <div>
                  <!-- Button trigger modal -->
                  <a role="button" @click="showModal = true" class="account-button" data-toggle="modal" data-target="#exampleModalCenter">
                    Sign out from all devices
                  </a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


  <!-- Modal -->
  <div class="modal d-block" v-if="showModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header align-self-center">
          <h4 class="modal-title fw-bold">Sign out</h4>
          <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close" @click="showModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">Do you want to sign out from all the devices?</div>
        <div class="modal-footer align-self-center align-items-center">
          <a class="btn cancel-btn py-1 fw-bold fs-5 shine" role="button" data-dismiss="modal" @click="showModal = false">Cancel</a>
          <a class="btn signout-popup text-white py-1  px-5 fw-bold fs-5 shine" role="button" @click="doLogoutFromAll">Yes</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for regions -->

  <div class="modal d-block" v-if="showRegionModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content align-items-center">
        <div class="modal-header">
            <div v-if="showRegionModalProcessing" class="text-center">
     
                  <div class="spinner-container py-3 align-items-baseline">
                    <span class="spinner-grow spinner-grow-sm text-info-icon me-1" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    <span class="spinner-grow spinner-grow-sm text-info-icon  me-1" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    <span class="spinner-grow spinner-grow-sm text-info-icon" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                  </div>
            </div>

            <div v-else  class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 16 16" class="bi bi-check-circle">
                <defs>
                    <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style="stop-color:rgba(0, 80, 166, 1); stop-opacity:1" />
                        <stop offset="100%" style="stop-color:rgba(30, 208, 214, 1); stop-opacity:1" />
                    </linearGradient>
                </defs>
                <path fill="url(#iconGradient)" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path fill="url(#iconGradient)" d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
            </svg>
            <h5 class="modal-title fw-bold text-info-2 pt-2">Success</h5>
            </div>
                       

        </div>
        <div v-if="showRegionModalProcessing" class="modal-body pt-0">Changing CDN Region.</div>
        <div v-else class="modal-body pt-0">Your region has been successfully changed.</div>

      </div>
    </div>
  </div>
  <!-- Modal for video quality -->
  <div class="modal d-block" v-if="showVideoQualityModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content align-items-center">
        <div class="modal-header">
            <div v-if="showVideoQualityProcessing" class="text-center">
     
            <div class="spinner-container py-3 align-items-baseline">
                    <span class="spinner-grow spinner-grow-sm text-info-icon me-1" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    <span class="spinner-grow spinner-grow-sm text-info-icon  me-1" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    <span class="spinner-grow spinner-grow-sm text-info-icon" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                  </div>
            </div>

            <div v-else  class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 16 16" class="bi bi-check-circle">
                <defs>
                    <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style="stop-color:rgba(0, 80, 166, 1); stop-opacity:1" />
                        <stop offset="100%" style="stop-color:rgba(30, 208, 214, 1); stop-opacity:1" />
                    </linearGradient>
                </defs>
                <path fill="url(#iconGradient)" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path fill="url(#iconGradient)" d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
            </svg>
            <h5 class="modal-title fw-bold text-info-2 pt-2">Success</h5>
            </div>
                       

        </div>
        <div v-if="showVideoQualityProcessing" class="modal-body pt-0">Changing video quality.</div>
        <div v-else class="modal-body pt-0">Your video quality has been successfully changed.</div>

      </div>
    </div>
  </div>
  <!-- Modal for Renew / Upgrade plan -->
  <div class="modal d-block" v-if="showPlanModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header align-self-center">
          <h4 class="modal-title fw-bold">Renew / Upgrade</h4>
          <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close" @click="showPlanModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">To renew or upgrade your plan, please visit our main website, Procricket.tv, and navigate to the ‘My Account’ section.</div>
        <div class="modal-footer align-self-center align-items-center">
          <a class="btn cancel-btn py-1 fw-bold fs-5 shine" role="button" data-dismiss="modal" @click="showPlanModal = false">Cancel</a>
          <a href="https://procricket.tv/" class="btn signout-popup text-white py-1  px-5 fw-bold fs-5 shine" role="button" @click="showPlanModal = false">Visit</a>
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import {defineComponent} from 'vue'
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import VueSimpleAlert from 'vue3-simple-alert'
import UsersService from "@/services/users-service";
import AuthService from "@/services/auth-service";
import RegionService from "@/services/region-service";
import VideoQualityService from "@/services/video-quality-service";

export default defineComponent({
  name: "Account",
  components: {
    Footer,
    Header
  },
  data() {
    return {
      userPlan: null,
      userProfile: null,
      planEndDate: '',
      showModal: false,
      showRegionModal: false,
      showPlanModal: false,
      showRegionModalProcessing: false,
      showVideoQualityModal: false,
      showVideoQualityProcessing: false, // Added
      regions: null,
      videoQualityList: null,
      selectedRegion: "null",
      selectedVideoQuality: "Auto"
    }
  },
  computed: {
    orderedVideoQualities() {
      // Map the video qualities to objects with modified keys
      const modifiedQualities = Object.keys(this.videoQualityList).map(key => {
        return {
          name: key,
          value: this.videoQualityList[key]
        };
      });
      // Sort the modified qualities based on the defined order
      const sortedQualities = modifiedQualities.sort((a, b) => {
        // Move "Auto" to the front
        if (a.name === 'Auto') return -1;
        if (b.name === 'Auto') return 1;
        // Get the numeric part of the keys
        const aNum = parseInt(a.name.replace(/[^0-9]/g, ''));
        const bNum = parseInt(b.name.replace(/[^0-9]/g, ''));
        // Compare the numeric parts of the keys
        return bNum - aNum; // Sort in descending order based on the numeric part
      });
      return sortedQualities;
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userPlan') == null || localStorage.getItem('userProfile') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }

    if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
    if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
    if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
    if (localStorage.getItem('coupon')) {
      localStorage.setItem('coupon', null)
    }
    if (localStorage.getItem('regions')) this.regions = JSON.parse(localStorage.getItem('regions'))
    if (this.userProfile.region !== undefined) this.selectedRegion = this.userProfile.region
    if (localStorage.getItem('videoQualityList')) this.videoQualityList = JSON.parse(localStorage.getItem('videoQualityList'))
    if (this.userProfile.videoQuality !== undefined) this.selectedVideoQuality = this.userProfile.videoQuality
  },
  beforeCreate() {
    if (localStorage.getItem('password') == "true") {
      localStorage.setItem('password', "false")
      VueSimpleAlert.alert("Your password has been successfully changed.", "Password changed")
    }
  },
  methods: {
    doChangePassword: function () {
      this.$router.push('/change-password')
    },
    doLogoutFromAll: function () {
      UsersService.logoutFromAllDevices().then((loginId) => {
        if (loginId != '') {
          const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
          oldUserLatest.loginId = loginId
          localStorage.setItem('userLatest', JSON.stringify(oldUserLatest))
          this.showModal = false
          VueSimpleAlert.alert("You've been successfully logged out of all devices (except this)", "Logged out")
        }
      })
    },
    doContactSupport: function () {
      window.open("https://procricket.help/contact/");
    },
    doSignOut() {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    handleRegionChange() {
        this.showRegionModal = true;

      this.showRegionModalProcessing = true; // Show processing message
      if (this.selectedRegion === "null") {
        RegionService.updateUserRegionNull().then(() => {
           setTimeout(() => {
            this.showRegionModalProcessing = false; // Hide processing message
          }, 1000);
          this.showRegionModalWithDuration()
        })
      } else {
        RegionService.updateUserRegion(this.selectedRegion).then(() => {
           setTimeout(() => {
            this.showRegionModalProcessing = false; // Hide processing message
          }, 1000);
          this.showRegionModalWithDuration()
        })
      }
    },
    showRegionModalWithDuration() {
      // this.showRegionModal = true
      setTimeout(() => {
        this.showRegionModal = false;
      }, 2000);
    },
    handleVideoQualityChange() {
            this.showVideoQualityModal = true;

      this.showVideoQualityProcessing = true; // Show processing message
      if (this.selectedVideoQuality === "Auto") {
        VideoQualityService.updateUserVideoQualityNull().then(() => {
          setTimeout(() => {
            this.showVideoQualityProcessing = false; // Hide processing message
          }, 1000);
          this.showVideoQualityModalWithDuration()
        })
      } else {
        VideoQualityService.updateUserVideoQuality(this.selectedVideoQuality).then(() => {
          setTimeout(() => {
            this.showVideoQualityProcessing = false; // Hide processing message
          }, 1000);
          this.showVideoQualityModalWithDuration()
        })
      }
    },
    showVideoQualityModalWithDuration() {
      setTimeout(() => {
        this.showVideoQualityModal = false;
      }, 2000);
    },
    
  }
})
</script>

<style>
@import '../assets/style.css';
button.close.close-modal {background: white;border: 1px solid #fff;color: #333;position: absolute;right: 0;top: 0;font-size: 28px; border-radius: 5px;}
.pop-btn{background: linear-gradient(90deg, rgba(30,208,214,1) 0%, rgba(0,80,166,1) 100%); min-height: 48px;}
.signout-popup, .signout-popup:hover, .signout-popup:focus {background: linear-gradient(90deg, rgba(30, 208, 214, 1) 0%, rgba(0, 80, 166, 1) 100%); border: 0;border-radius: 0px !important;}
.swal2-title{font-size: 1.5em !important;}
.cancel-btn{border-radius: 0px !important;}
.swal2-styled.swal2-confirm{background: linear-gradient(90deg, rgba(30, 208, 214, 1) 0%, rgba(0, 80, 166, 1) 100%) !important; font-size: 1.25em !important;padding: .25em 3em;border-radius: 0px !important;}
.text-info-icon {--bs-text-opacity: 1;background: linear-gradient(-90deg, rgba(30, 208, 214, 1) 0%, rgba(0, 80, 166, 1) 100%);}
.text-info-2{color:#1ebacb}
</style>
