import apiClient from '@/utils/api-client'

class VideoQualityService {

    public updateUserVideoQuality(quality: string) {
        return new Promise<boolean>((resolve, reject) => {
            localStorage.removeItem('dynamicData');
            apiClient.get('/api/v1/video-quality/update?quality=' + quality).then(() => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public updateUserVideoQualityNull() {
        return new Promise<boolean>((resolve, reject) => {
            localStorage.removeItem('dynamicData');
            apiClient.get('/api/v1/video-quality/update').then(() => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

}

export default new VideoQualityService()
